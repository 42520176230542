#root .app-container {
  min-height: calc(80vh - 140px);
  
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
  
  margin: 30px auto;
  max-width: 540px;
}

#root .info-div {
  padding: 30px 30px 60px 30px;
  box-shadow: 0 1px 4px 0 rgba(46,51,51,.05);
  background-color: #fff;
}

.app-container .MuiButton-containedPrimary {
  /* max-width: 720px; */
  background-color: #FF4C5A;
  font-weight: bold;
}

#root .MuiButton-containedPrimary:hover {
  /* background-color: #29948c; */
  background-color: #ab202b;
}

.app-container h3{
  color: #2e3333;
  font-weight: bold;
  margin-bottom: 30px;
}

#root .MuiToolbar-gutters {
  width: calc(100% - 2 * 16px);
  padding: 0 16px;
  margin: 0 auto;
  max-width: 1120px;
}

#root .MuiFormLabel-root.Mui-focused:not(.Mui-error), #root .MuiRadio-colorSecondary.Mui-checked:not(.Mui-error){
  color: #FF4C5A;
}
#root .MuiInput-underline:not(.Mui-error)::after {
  border-bottom: 2px solid #FF4C5A;
}

#root .MuiChip-label, #root .MuiChip-deleteIcon {
  font-weight: bold;
  color: #fff;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
}

#root .MuiChip-root {
  background-color: #FF4C5A;
  max-width: 100%;
}

#root .video-frame {
  width: 100%;
  height: 180px;
}

@media (min-width:540px) {
  #root .video-frame {
    height: 280px;
  }
}

@media only screen and (min-width: 540px){
  #root .MuiToolbar-gutters {
    width: calc(100% - 2 * 24px);
    padding: 0 24px;
  }
}

@media only screen and (min-width: 960px){
  #root .MuiToolbar-gutters {
    width: calc(100% - 2 * 32px);
    padding: 0 32px;
  }
}

@media only screen and (min-width: 1440px){
  #root .MuiToolbar-gutters {
    width: calc(100% - 2 * 64px);
    padding: 0 64px;
  }
}

#root .MuiAppBar-colorPrimary {
  background-color: #fff;
  box-shadow: 0 0 0 0;
  border-bottom: 1px solid rgba(0,0,0,.08);
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#root .button-next-back {
  background:#fff;
  bottom: 0;
  box-shadow: 0 -1px 4px rgba(0,0,0,.05);
  left: 0;
  padding: 16px;
  position: fixed;
  right: 0;
}

@media only screen and (min-width: 540px){
  #root .button-next-back {
    box-shadow: none;
    padding: 30px;
    position: static;
  }
}

#root .roadmap-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(0,0,0,0.06)
}

#root .roadmap-item span {
  color: #FF4C5A
}

#root .roadmap-details {
  flex: 1
}

#root .roadmap-item h5 {
  color: #2e3333
}